.fa {
	border: 1px solid var(--color-secondary);
	border-radius: 999px;
	box-shadow: var(--shadow);
	cursor: pointer;
	font-size: 3em;
	margin-bottom: var(--spacing-small);
	padding: var(--spacing-small);
}

.hover-enter {
	border: 1px solid var(--color-secondary);
	box-shadow: var(--shadow);
	transform: scale(100%);
}

.hover-enter-active {
	border: 1px solid var(--color-primary);
	box-shadow: var(--shadow-highlight);
	transform: scale(105%);
	transition: all 300ms;
}

.hover-enter-done {
	border: 1px solid var(--color-primary);
	box-shadow: var(--shadow-highlight);
	transform: scale(105%);
}

.hover-exit {
	border: 1px solid var(--color-primary);
	box-shadow: var(--shadow-highlight);
	transform: scale(105%);
}

.hover-exit-active {
	border: 1px solid var(--color-secondary);
	box-shadow: var(--shadow);
	transform: scale(100%);
	transition: all 300ms;
}

.hover-exit-done {
	border: 1px solid var(--color-secondary);
	box-shadow: var(--shadow);
	transform: scale(100%);
}
