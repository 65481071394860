.sin-item {
	display: inline-block;
	background-color: var(--color-secondary);
	border: 1px solid var(--color-secondary);
	border-radius: 3px;
	box-shadow: var(--shadow);
	cursor: pointer;
	font-size: 2em;
	margin: 0;
	padding: var(--spacing-small);
	position: relative;
}

.consumed {
	color: var(--color-highlight);
	text-decoration: line-through;
}

.hover-enter {
	border: 1px solid var(--color-secondary);
	box-shadow: var(--shadow);
	transform: scale(100%);
}

.hover-enter-active {
	border: 1px solid var(--color-primary);
	box-shadow: var(--shadow-highlight);
	transform: scale(105%);
	transition: all 300ms;
}

.hover-enter-done {
	border: 1px solid var(--color-primary);
	box-shadow: var(--shadow-highlight);
	transform: scale(105%);
}

.hover-exit {
	border: 1px solid var(--color-primary);
	box-shadow: var(--shadow-highlight);
	transform: scale(105%);
}

.hover-exit-active {
	border: 1px solid var(--color-secondary);
	box-shadow: var(--shadow);
	transform: scale(100%);
	transition: all 300ms;
}

.hover-exit-done {
	border: 1px solid var(--color-secondary);
	box-shadow: var(--shadow);
	transform: scale(100%);
}
