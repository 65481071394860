.footer {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-primary);
	width: 100%;
}

.footer li {
	color: var(--color-secondary);
	text-align: center;
	margin: var(--spacing-small);
}

.footer a {
	color: var(--color-secondary);
}

@media only screen and (min-width: 1024px) {
	.footer li {
		display: inline-block;
		margin: var(--spacing-small) 0;
	}

	.footer li::before {
		content: "|";
		margin: 0 var(--spacing-xsmall);
	}

	.footer li:first-child::before {
		content: "";
		margin: 0;
	}
}
