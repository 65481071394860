.main-body {
	background-color: var(--color-secondary);
	color: var(--color-primary);
	width: 100%;
}

.main-body article {
	margin: 0 auto;
	padding: var(--spacing-small);
	width: 100%;
}

.disclaimer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: var(--spacing-small);
}

.disclaimer h2 {
	font-size: 1.5em;
	font-weight: var(--text-bold);
	text-decoration: underline;
	margin-bottom: var(--spacing-small);
}

.disclaimer p {
	font-size: 1em;
	text-align: justify;
	margin-bottom: 1em;
	width: 100%;
}

.disclaimer p:last-child {
	margin-bottom: 0;
}

.sins {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media only screen and (min-width: 1024px) {
	.main-body article {
		max-width: 1200px;
	}

	.disclaimer h2 {
		font-size: 2em;
	}

	.disclaimer p {
		font-size: 1.35em;
	}
}
