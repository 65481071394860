:root {
	--color-primary: #cb2b23;
	--color-secondary: #fefefe;
	--color-highlight: #ffdddd;

	--spacing-xsmall: 10px;
	--spacing-small: 20px;
	--spacing-large: 40px;

	--shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
	--shadow-highlight: 0 5px 7px rgba(0, 0, 0, 0.15);

	--font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif;
	--text-bold: 700;
	--text-shadow: 0 2px 4px rgba(240, 0, 0,0.25);
}

body {
	font-family: var(--font-family);
}
